.loading {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.loading p {
  color: #fe4900;
  font-size: 29px;
  font-weight: 900;
}

.lds-ellipsis {
  display: inline-block;
  height: 80px;
  position: relative;
  width: 80px;
}

.lds-ellipsis div {
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  background: #fe4900;
  border-radius: 50%;
  height: 13px;
  position: absolute;
  top: 33px;
  width: 13px;
}

.lds-ellipsis div:nth-child( 1 ) {
  animation: lds-ellipsis1 0.6s infinite;
  left: 8px;
}

.lds-ellipsis div:nth-child( 2 ) {
  animation: lds-ellipsis2 0.6s infinite;
  left: 8px;
}

.lds-ellipsis div:nth-child( 3 ) {
  animation: lds-ellipsis2 0.6s infinite;
  left: 32px;
}

.lds-ellipsis div:nth-child( 4 ) {
  animation: lds-ellipsis3 0.6s infinite;
  left: 56px;
}

@keyframes lds-ellipsis1 {

  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {

  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {

  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}
